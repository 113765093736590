import Pusher from 'pusher-js';

class AssistantConnector {
    
    m_pusher = null;

    constructor() {
        
    }
  
   
    connect = (phnNumber, callback) => {
        console.log("Running");
        if (!this.m_pusher) {
            this.m_pusher = new Pusher("f09a2678910e563fa6a7", {
                cluster: "ap2",
            });

            var channel = this.m_pusher.subscribe("my-channel");
            
            const event = `msisdn-${phnNumber}`
            channel.bind(event, callback);
            
            console.log("Successfully connected for event: ", event);
        }


    }

    disconnect = () => {
        this.m_pusher.disconnect();
        this.m_pusher = null;
    }
}

export default AssistantConnector;