
class UIManager {
    
    m_div = null;

    constructor() {
        
    }
  
   
    show = (message) => {
        this.hide();
        this.m_div = document.createElement('div');
        this.m_div.innerHTML = `
            <div
                id="parentContainer"
                style="position: absolute; bottom: 0; right: 12px; z-index: 9999; width: 214px; height:250px; background-color: #F6F6F6; border-top-left-radius: 12px; border-top-right-radius: 12px; overflow: hidden;">
                <script>setInitialValue(data)</script>
            
                <!-- Header -->
                <div style="width:100%; height:36px; background-color: #20325E; ">
                <div
                    style="width: 25px; height:25px; margin-top: 6px; margin-left: 12px;border-radius:14px; float:left; background: white;">
                    <img style="width: 27px; height: 26px;" src='https://i.postimg.cc/dZ8t6BYG/bird-2x.png' border='0' alt='bird-2x'/>
                    <!-- <img style="width: 27px; height: 26px;" src='https://i.postimg.cc/rd3rYQvH/bird.png' border='0' alt='bird'/> -->
                </div>
                <div
                    style="width:160px; height:22px; margin-top: 7px; padding-left: 12px; color: white; float:right; line-height: 22px;">
                    <span>CERF</span>
                    <span style="float: right; font-size: 14px; font-weight: bold; padding-right:15px; cursor:pointer;" onclick="(function(){document.getElementById('parentContainer').remove();})();">x</span>
                </div>
                </div>
                
                <div style="display: flex;margin: 5px;">
                <div style="flex: 15%;width: 25px; height:25px; margin-top: 6px; margin-left: 12px;border-radius:14px; ">
                    <img style="width: 28px; height: 28px;background: #20325E;border-radius: 16px;" src='https://i.postimg.cc/dZ8t6BYG/bird-2x.png' border='0' alt='bird-2x'/>
                </div>
                <div style="flex: 5%"></div>
                <div style="flex: 80%;margin-top: 6px; background: #FFFFFF 0% 0% no-repeat padding-box; border-radius: 3px; opacity: 1; padding: 3px; border: 0.10000000149011612px solid #e9e7e7;font-size: 12px;">
                <span id="containerMessage">
                ${message}
                </span>
                </div>
                </div>
            </div>
            `
    document.body.appendChild(this.m_div);

    }

    hide = () => {
        if (this.m_div) {
            this.m_div.remove();
            this.m_div = null;
        }
    }
}

export default UIManager;