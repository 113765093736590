import AssistantConnector from "./connector";
import UIManager from "./uiManager";


class CerfAssistant {
    
    m_instance = null;
    m_isAuthenticated = false;
    m_connector = null;
    m_uiManager = null;

    constructor() {
        this.m_uiManager = new UIManager();
    }
  
    static getInstance(conString) {
      if (!this.m_instance) {
        this.m_instance = new CerfAssistant();        
      }
  
      return this.m_instance;
    }

    check = () => {
        console.log("Module working");
    }


    init = async (enterpriseId, phoneNumber, secret) => {
        await new Promise((res, rej) => {
            setTimeout(() => {
                this.m_isAuthenticated = true;
                res(true);
            }, 1000);        
        });        
    }

    onMessage = (message) => {
        console.log("Received", message);
        this.m_uiManager.show(message);
    }

    startListening = async (phoneNumber) => {

        if (!this.m_isAuthenticated) {
            // throw
            console.log("Not authenticated");
        }

        if (!this.m_connector) {
            console.log("check",AssistantConnector);
            this.m_connector = new AssistantConnector();
            this.m_connector.connect(phoneNumber, this.onMessage);
        }
        else {
            this.m_connector.disconnect();
            this.m_connector.connect(phoneNumber, this.onMessage);
        }
    }


    stopListening = () => {
        if (!this.m_connector) {
            this.m_connector.disconnect();
        }
    }
}


export default CerfAssistant.getInstance();

